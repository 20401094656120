import React from 'react';

import gsap from 'gsap';
import { CSSPlugin } from 'gsap/CSSPlugin'

import { shuffle, isEmpty, filter } from 'lodash';
import { Artwork, ArtworkProps } from './components/Artwork';
import './App.scss';

import logo from './img/konkai-logo-large.png';
import bgImage from './img/konkai-background.png';

import artStore from './art.json';

// Force CSSPlugin to not get dropped during build
gsap.registerPlugin(CSSPlugin)

type ArtStore = {
    artworks: ArtworkProps[];
}

type ArtworksState = {
    renderList: ArtworkProps[];
    onScreen: ArtworkProps;
}

class Artworks extends React.Component<ArtStore, ArtworksState> {
    constructor(props: ArtStore) {
        super(props);
        
        console.log(this.props.artworks.length);
        let renderList = shuffle(this.props.artworks);
        let onScreen = renderList.pop()!;
        
        this.state = {  renderList, onScreen };
    }

    private updateArt() {
        let { renderList } = this.state;
        if (isEmpty(renderList))
            renderList = filter(shuffle(this.props.artworks), (art) => art.imageSrc !== this.state.onScreen.imageSrc);
        let onScreen = renderList.pop()!;
        
        this.setState({ onScreen, renderList });
    }

    render() {
        return <>
            {[1].map(_ => <Artwork key={this.state.onScreen.imageSrc} 
                    callback={this.updateArt.bind(this)} {...this.state.onScreen}/>)}
        </>;
    }
}

type BackgroundProps = {};

class Background extends React.Component<BackgroundProps> {
    private bgRef: React.RefObject<HTMLImageElement>;

    constructor(props: BackgroundProps) {
        super(props);

        this.bgRef = React.createRef();
    }

    render() {
        return <img 
            ref={this.bgRef}
            className="background-image" 
            src={bgImage}
            alt="BG" />;
    }
}

const LogoGif = () => <>
    <img className="main-logo-gif" src={logo} alt="Fox Stevenson Boi Logo"></img>
</>;

function App() {
  return <>
    <div className="App-header">
        <Background />
        <LogoGif />
        <Artworks {...artStore as ArtStore}/>
    </div>
  </>;
}

export default App;
